<template>
  <div>
    <div class="card-toolbar mb-5">
      <router-link v-if="$can('jornal_entries.create')" to="/accounting/journal-entries/create" class="btn btn-primary font-weight-bolder">
        <v-icon>mdi-plus</v-icon>
        {{ $t('journal_entries.journal_entries') }}
      </router-link>
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">
            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('journal_entries.document_type') }}</label>
              <!-- <select name="document_type" id="document_type" v-model="filters.document_type" class="custom-select">
                  <option v-for="row in document_type_list" :value="row.slug" :key="row.id">{{row.title}}</option>
              </select> -->
              <multiselect
                  v-model="document_type"
                  :placeholder="$t('journal_entries.document_type')"
                  label="title"
                  track-by="id"
                  :options="document_type_list"
                  :multiple="false"
                  :taggable="false"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false">
              </multiselect>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="transaction_id">{{ $t('journal_entries.transaction_id') }}</label>
              <input v-model="filters.transaction_id" type="text" id="transaction_id" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('balance_sheet.financial_year') }}</label>
              <multiselect v-model="financial_year"
                           :placeholder="$t('balance_sheet.financial_year')"
                           label="name"
                           track-by="id"
                           :options="financial_years_list"
                           :multiple="false"
                           :taggable="false"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false">
              </multiselect>

            </div>
            <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
              <b-button class=" my-auto mb-0 mr-2" variant="primary" @click="doFilter"><i class="fas fa-search"></i> {{ $t('search') }}</b-button>
              <b-button class=" my-auto mb-0 mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}</b-button>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::User-->
    <div class="card card-custom">

      <div class="card-body">
        <!-- <div>
            <div class="row justify-content-end p-4">
                <export-data :data-list="dataList" :fields="json_fields" :file-name="$t('MENU.journal_entries')"></export-data>
            </div>
        </div> -->

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
          <template slot="operation" slot-scope="props">
            <div class="w-100">{{ props.row.date }} (#{{ props.row.id }})</div>
            <div class="w-100" v-if="props.row.transaction_id">{{ props.row.name }} #{{ props.row.transaction_id }}</div>
          </template>
          <template slot="debit_credit" slot-scope="props">
            <div class="d-flex justify-content-center">
              <div class="d-flex">
                <h5 class="m-2" v-for="(dd, index) in props.row.debit_accounts" :key="index">
                  <span class="badge badge-secondary">{{ dd }}</span>
                </h5>
              </div>
              <div><i class="fas fa-arrow-right as-right" style="line-height: 2.5;"></i></div>
              <div class="d-flex">
                <h5 class="m-2" v-for="(cc, index) in props.row.credit_accounts" :key="'cc'+index">
                  <span class="badge badge-secondary">{{ cc }}</span>
                </h5>
              </div>
            </div>
          </template>
          <template slot="amount" slot-scope="props">
            {{ props.row.amount }} {{ props.row.currency_name }}
          </template>
          <template slot="status" slot-scope="props">
            <!--                        <button v-if="$can('jornal_entries.update')" @click="changeStatus(props.row.id, props.row.status)" class="btn btn-secondary m-btn m-btn&#45;&#45;icon w-auto">-->
            <!--                            {{$t('change_status')}} ({{ props.row.status_name }})-->
            <!--                        </button>-->
            {{ props.row.status_name }}
          </template>
          <template slot="revert" slot-scope="props">
            <b-button v-if="$can('jornal_entries.revert')"
                v-model="props.row.is_revert"
                :disabled="props.row.is_revert == 1 ? true : false"
                size="sm" @click="RevertItem(props.row)" variant="outline-primary">
              {{ $t('revert') }}
            </b-button>
          </template>


          <template slot="actions" slot-scope="props">
            <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" color="blue darken-2" v-if="$can('jornal_entries.update')" @click="editItem(props.row)">mdi-pencil</v-icon>
            <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" color="red darken-2" v-if="$can('jornal_entries.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>
          </template>
        </v-server-table>


        <!--end: Datatable-->
      </div>
    </div>
    <!--end::User-->

    <!--        <b-modal ref="status-modal" size="md" hide-footer :title="$t('change_status')">-->
    <!--            <change-status-form @hide-modal="hideModal()"-->
    <!--                :status-list="status_list"-->
    <!--                :url="routeChangeStatus"-->
    <!--                :current-id="innerId"-->
    <!--                :current-status="statusId"-->
    <!--                @handling-data="getDataAfterChangeStatus">-->
    <!--            </change-status-form>-->
    <!--        </b-modal>-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
// import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm";

export default {
  name: "index-journal-entries",
  // components: {'change-status-form': ChangeStatusFrom,},
  data() {
    return {
      mainRoute: 'accounting/jornal-entries',
      subMainRoute: 'accounting/jornal-entries',
      mainRouteDependency: 'base/dependency',
      routeChangeStatus: 'accounting/jornal-entry/change-status',
      routeRevertStatus: 'accounting/jornal-entry/revert-status',
      showAdvancedSearch: false,
      dataList: [],
      filters: {
        document_type: null,
        transaction_id: null,
        financial_year_id: null,
      },

      status_list: [],
      document_type: null,
      document_type_list: [],
      categories: [],
      currencies: [],
      // 'serial_no', 'journal_date', 'currency_name', 'added_by',
      columns: ['operation', 'debit_credit', 'amount', 'status', 'revert', 'actions'],

      innerId: null,
      statusId: null,
      financial_years_list: [],
      financial_year: null,
    }
  },
  watch: {
    document_type: function (val) {
      if (val) {
        this.filters.document_type = val.slug;
      } else {
        this.filters.document_type = null;
      }
    },
    financial_year: function (val) {
      if (val) {
        this.filters.financial_year_id = val.id;
      }
    },
  },
  computed: {
    // json_fields: function () {
    //     let fields = {};
    //     fields[this.$t('journal_entries.serial_no')] = 'serial_no';
    //     fields[this.$t('journal_entries.journal_date')] = 'journal_date';
    //     fields[this.$t('currency')] = 'currency_name';
    //     fields[this.$t('added_by')] = 'added_by';
    //     fields[this.$t('status')] = 'status_name';

    //     return fields;
    // },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          // serial_no: that.$t('journal_entries.serial_no'),
          // journal_date: that.$t('journal_entries.journal_date'),
          // currency_name: that.$t('currency'),
          // added_by: that.$t('added_by'),
          operation: that.$t('journal_entries.operation'),
          debit_credit: that.$t('journal_entries.debit_credit'),
          amount: that.$t('journal_entries.amount'),
          status: that.$t('status'),
          revert: that.$t('revert'),
          actions: that.$t('actions'),

        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: 0,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRoute, {..._params});

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        },

      }

    },
  },
  mounted() {

    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.journal_entries")}]);
    this.getDocumentTypeList();
    this.getStatusList();
    this.getFinancialYears();
    // this.getCategories();
    // this.getCurrencies();

  },
  methods: {
    RevertItem(item) {
      this.$confirmAlert(this.$t('confirm_revert'), this.Revert, item);
    },
    Revert(item) {
      ApiService.patch(`${this.routeRevertStatus}/${item.id}`, {
        is_revert: (item.is_revert == 0 ? 1 : 0)
      })
          .then((response) => {
            this.getFetch();
            this.$successAlert(response.data.message)
          }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    getFetch() {
      this.$refs.table.refresh();
    },
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.document_type = null;
      this.filters.transaction_id = null;
      this.filters.financial_year_id = null;
      this.financial_year = null;
      this.$refs.table.refresh();
    },
    actionDelete(item) {
      ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    editItem(item) {
      this.$router.push({name: 'journal-entries.edit', params: {id: item.id}});
    },
    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },

    updateValue(value) {
      this.filters.category_ids = value
    },
    loadOptions() {
    },

    getCategories() {
      ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
        this.categories = response.data.data;
      });
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getDocumentTypeList() {
      ApiService.get(this.mainRouteDependency + "/doc_type_list").then((response) => {
        this.document_type_list = response.data.data;
      });
    },
    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/status").then((response) => {
        this.status_list = response.data.data;
      });
    },
    getFinancialYears() {
      ApiService.get(this.mainRouteDependency + "/all_financial_years").then((response) => {
        this.financial_years_list = response.data.data.map((row) => {
          return {id: row.id, name: row.start_date + ' - ' + row.end_date};
        });
      });
    },
    showModal() {
      this.$refs['status-modal'].show()
    },
    hideModal() {
      this.$refs['status-modal'].hide();
    },
    changeStatus(id, status) {
      this.innerId = id;
      this.statusId = status;
      this.showModal();
    },
    getDataAfterChangeStatus() {
      this.innerId = null;
      this.statusId = null;
      this.doFilter();
    },


    // changeStatus(id, status) {
    //     ApiService.patch(this.subMainRoute + '/change-status/' + id, {
    //         status: (status ? '1' : '0'),
    //     }).then(response => {
    //         this.$refs.table.refresh();
    //         this.$successAlert(response.data.message);
    //     }).catch(error => {
    //         this.$errorAlert(error);
    //     });
    // },

  },
};
</script>
